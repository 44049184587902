import { HomeRoot } from './Home.styles'
import { Layout } from 'components/Layout'
import { Header } from 'components/Header'
import { useState } from 'react'
import { Hero } from './components/Hero'
import { MealFeatures } from './components/MealFeatures'
import { FullTimeJob } from './components/FullTimeJob'
import { Steps } from './components/Steps'
import { NutritionClaim } from './components/NutritionClaim'
import { PetBenefits } from './components/PetBenefits'
import { Ingredients } from './components/Ingredients'
import { ReviewSlider } from '../components/ReviewSlider'
import { FamilyStory } from './components/FamilyStory'
import { CtaFooter } from './components/CtaFooter'
import { Footer } from 'components/Footer'
import { useObserver } from 'hooks/useObserver'

export const Home = () => {
  const header = <Header />
  
  const selector = 'a.cta'

  const hideCta = useObserver({ selector })

  return (
    <HomeRoot>
      <Layout header={header} hideCTA={hideCta}>
        <Hero />
        <FullTimeJob />
        <Steps />
        <MealFeatures/>
        <PetBenefits />
        <Ingredients />
        <NutritionClaim />
        <ReviewSlider color='accent-white' background='secondary-500' />
        <FamilyStory />
        <CtaFooter title="home:ending.title" />
        <Footer />
      </Layout>
    </HomeRoot>
  )
}

Home.displayName = 'Home'
