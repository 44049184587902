import { styled } from 'stitches.config'
import { Stack } from 'components/Stack'
import { Text } from 'components/Text'

export const FullTimeJobRoot = styled('div', {
  width: '100%',
  backgroundColor: '$accent-white'
})

export const FullTimeJobContainer = styled('div', {
  maxWidth: '500px',
  margin: '0 auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  
  '@bp2': {
    maxWidth: '1440px',
    flexDirection: 'row',
  }
})

export const TextColumn = styled('div', {
    width: '100%',
    padding: '$3 $2 $3 $2',

    '@bp2': {
        width: '50%',
        padding: '$4'
    },

    '@bp3': {
        padding: '$6',
    }
})

export const Title = styled(Text, {
    width: '100%',
    maxWidth: '330px',
    '@bp2': {
        maxWidth: '500px'
    }
})

export const Description = styled(Text, {
    width: '100%',
    maxWidth: '350px',
    '@bp2': {
        maxWidth: '560px'
    }
})