import {
  FullTimeJobRoot,
  FullTimeJobContainer,
  TextColumn,
  Title,
  Description,
} from './FullTimeJob.styles'
import { Stack } from 'components/Stack'
import { useTranslation } from 'hooks/useTranslation'

export const FullTimeJob = () => {
  const { t } = useTranslation()

  return (
    <FullTimeJobRoot>
      <FullTimeJobContainer>
        <TextColumn>
          <Stack gap='2' direction="column" alignItems='center'>
            <Title align='center' font="display" size={{'@initial': 'h3', '@bp3': 'h1'}}>
              {t('home:mealPlan.title')}
            </Title>
            <Description  align='center'  size="body">
              {t('home:mealPlan.subtitle')}
            </Description>
          </Stack>
        </TextColumn>
      </FullTimeJobContainer>
    </FullTimeJobRoot>
  )
}
